import React, { useCallback } from "react";
import ReactMarkdown from "react-markdown";
import { ReactComponent as MyFlex } from "./assets/my-flexibility.svg";
import Matriz from "./assets/matriz-elegibilidad.png";
// import { isFlexibilityActive } from '../../utils/isTravelBenefitsActive';
import { ReactComponent as EnSitio } from "./assets/en-sitio.svg";
import { ReactComponent as EnSitioEn } from "./assets/en-sitio-ingles.svg";
import { ReactComponent as Parcial } from "./assets/parcial.svg";
import { ReactComponent as ParcialEn } from "./assets/parcial-ingles.svg";
import { ReactComponent as Remoto } from "./assets/remoto.svg";
import { ReactComponent as RemotoEn } from "./assets/remoto-ingles.svg";
import { Redirect } from "react-router";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import FaqsNacional from "./FaqsNacional";
import FaqsInternacional from "./FaqsInternacional";
import i18next from "i18next";
import { validateFlexStatus } from "../../utils/isTravelBenefitsActive";

import "./flexStyle.css";

import { isLocal, isFromDev, isFromQA } from './../../core/Ambientes';
import flexDays from "./assets/flexdays_title.svg";
import flexLeave from "./assets/flexdays_title.svg";
import flexPlace from "./assets/flexplace_title.svg";
import flexTime from "./assets/flextime_title.svg";
import flexTable from "./assets/flextime_table.png";

	// # Variables que switchean las ligas que se consumen segun sea elcaso
	// const isLocal = process.env.REACT_APP_ISLOCAL; // Tomara entorno local
	// const isFromDev = process.env.REACT_APP_ISFROMDEV; //Se pondra en true para ambiente DEV
	// const isFromQA = process.env.REACT_APP_ISFROMQA; //Se pondra en true para ambiente QA
	/* Para productivo se ponen todas en falso */
	
	// #URl de ambiente
	const urlLocal = `${process.env.REACT_APP_LOCAL}`;
	const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
	const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
	const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`
	
	const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
	const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
	const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
	const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`
	
	// # Liga validada
	const urlValid = isLocal === true ? urlLocal 
		: isFromDev === true ? urlDev
		: isFromQA  === true? urlQA
		: urlProd
	
	// Se genera el token segun el ambiente
	const tokenValid = isLocal === true ? tokenLocal
		: isFromDev === true ? tokenDev
		: isFromQA === true ? tokenQA
		: tokenProd
	
		const cabecera = new Headers({
			"Content-type": "application/json",
			Authorization: "Bearer " + tokenValid
		});


const URL = `${urlValid}`;

const nombre = localStorage.getItem("nombre");
const apellidoPaterno = localStorage.getItem("apellidoPaterno");
const apellidoMaterno = localStorage.getItem("apellidoMaterno");

function Flexibility() {
	const [userData, setUserData] = useState(null);
	const [mainData, setMainData] = useState(null);
	//const [kitData, setKitData] = useState({});
	const [loading, setLoading] = useState(true);
	const [imBoss, setImBoss] = useState(false);
	const [interFaqs, setInterFaqs] = useState(null);
	const [internacional, setInternacional] = useState(false);
	const [canAccess, setCanAccess] = useState(null);
	const language = i18next.language;

	// const minionSchemeTranslation = (theScheme, internacional) => {
	// 	if (internacional) {
	// 		switch (theScheme) {
	// 			case "En sitio internacional":
	// 				if (language === "es") {
	// 					return "en-sitio";
	// 				} else {
	// 					return "On-site";
	// 				}
	// 			case "Parcial internacional":
	// 				if (language === "es") {
	// 					return "Parcial";
	// 				} else {
	// 					return "Partial";
	// 				}
	// 			default:
	// 				if (language === "es") {
	// 					return "Remoto";
	// 				} else {
	// 					return "Remote";
	// 				}
	// 		}
	// 	} else {
	// 		return theScheme;
	// 	}
	// };

	useEffect(() => {
		const country = localStorage.getItem("pais");
		const userScheme = localStorage.getItem("esquemaColaborador");
		const colaboradores = localStorage.getItem("colaboradores");
		let minions;
		let firstMinions;
		if(colaboradores !== "undefined"){
			const firstMinions = colaboradores !== "undefined" ? [JSON.parse(localStorage.getItem("colaboradores"))] : null;
			if (Array.isArray(colaboradores)) {
				minions = firstMinions;
			} else {
				minions = JSON.parse(colaboradores);
			}
		}else{
			minions = null;
		}

		
		console.log('colaboradores',firstMinions);
		//const minions = Array.isArray(firstMinions) ? firstMinions : null;
		console.log('minions',minions);
		// if (minions.length === 0) {
		// 	alert("NO ES VALIDO PERO VALIDA");
		// 	minions.push(firstMinions);
		// }
		const theScheme = validateFlexStatus(userScheme);
		const internacional = country !== "MX";
		if (theScheme !== null) {
			const defaultUserData = {
				employee: localStorage.getItem("noEmpleado"),
				name: `${nombre} ${apellidoPaterno} ${apellidoMaterno}`,
				scheme: theScheme,
				email: localStorage.getItem("email"),
				location: country
			};


			if (Array.isArray(minions)) { 
				const minionsArray = [];
				for (let i = 0; i < minions.length; i++) {
					minionsArray.push(minions[i]);
				}
				setUserData({
					...defaultUserData,
					isBoss: true,
					minions: minions
				});
				setImBoss(true);
			}else if( !Array.isArray(minions) && minions !== null){
				setUserData({
					...defaultUserData,
					isBoss: true,
					minions: [minions]
				});
			} else {
				setUserData({
					...defaultUserData,
					isBoss: false,
					minions: null
				});
			}
			setImBoss(false);
			getData(theScheme, internacional);
			setInternacional(internacional);
			const theFaqs = fetch(`${URL}/flex-faqs`,{ 
				method: "GET",
				headers: cabecera
			}).then((res) => res.json());
			setInterFaqs(theFaqs);
			setCanAccess(true);
		} else {
			// EN CASO DE QUE NO HAYA ESQUEMA
		}		
	}, []);

	const getData = (scheme, international = false) => {
		
		const theScheme = scheme.toLowerCase().replaceAll(" ", "-");
		const internationalURL = international ? "-international" : "";
		const regularURL = `${URL}${theScheme}${internationalURL}?populate=*`;
		
		
		fetch(`${regularURL}`,{ 
			method: "GET",
			headers: cabecera
		})
			.then((res) => res.json())
			.then((res) => {
				console.log("Regulare eschema: ",res)
				setMainData(res);
				setLoading(false);
				return res;
			});
	};

	if (canAccess !== null && canAccess) {
		return (
			<section className="mt-20 w-full text-amBlack">
				<header className="flex w-full items-center justify-center">
					<MyFlex className="h-20 w-auto" />
				</header>
				{loading ? (
					<>
						<Skeleton className="w-full" height={50} count={2} />
					</>
				) : (
					<> 
						{mainData.data.attributes.firstAlert ? (
							<div className="my-10 w-full rounded-lg bg-amYellow py-3">
								<div className="mx-auto flex items-center justify-center px-3 sm:px-6 lg:px-8">
									<span className="mr-3 flex rounded-lg bg-amBlack bg-opacity-10 p-1">
										<svg
											viewBox="0 0 24 24"
											xmlns="http://www.w3.org/2000/svg"
											className="h-6 w-6 fill-current text-amBlack">
											<path d="M0 0h24v24H0z" fill="none" />
											<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
										</svg>
									</span>

									<div className="p-1 text-sm sm:px-6 lg:px-8">
										<ReactMarkdown>{mainData.data.attributes.firstAlert}</ReactMarkdown>
									</div>
								</div>
							</div>
						) : null}
						<div className="flex w-full items-center justify-start rounded-lg bg-white px-8 py-3">
							<div className={`ImageUser ${localStorage.getItem("avatar")}`}></div>
							<div className="intel ml-4 flex flex-col items-start justify-center">
								<h1 className="text-center text-2xl font-bold text-amDarkBlue">
									¡{language === "es" ? "Hola" : "Hello"} {localStorage.getItem("nombre")}!
								</h1>
								<p className="w-full text-center text-lg leading-tight text-black">
									{
										mainData.data.attributes.role
									}
									{/* {language !== "es" && internacional
										? mainData.data.attributes.role
										: mainData.role}{" "} */}
									<strong>
										{/* {language !== "es" && internacional
											? mainData.localizations[0].type
											: mainData.type} */}
										{" " + mainData.data.attributes.type }
									</strong>
								</p>
							</div>
						</div>
						<div className="my-7 flex w-full items-center justify-center">
							{mainData.data.attributes.type === "Remoto" && (
								<>
									{language !== "es" ? (
										<RemotoEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<Remoto className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
							{mainData.data.attributes.type === "Parcial" && (
								<>
									{language !== "es" ? (
										<ParcialEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<Parcial className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
							{mainData.data.attributes.type === "En Sitio" && (
								<>
									{language !== "es" ? (
										<EnSitioEn className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									) : (
										<EnSitio className="w-8/12 sm:w-5/12 lg:w-3/12 h-auto" />
									)}
								</>
							)}
						</div>
					</>
				)}
				<div className="mt-14 grid w-full grid-cols-6 gap-6 rounded-lg bg-white">
					{!loading && (
						<div className={`${imBoss ? "col-span-3" : "grid-col-3-6"} p-8 pb-10`}>
							<h2 className="mb-2 text-xl font-bold tracking-tight text-amBlue sm:text-2xl">
								{language === "es" ? "Tu esquema" : "Your scheme"}
							</h2>
							<div className="prose w-full max-w-full">
								<ReactMarkdown>
									{language !== "es" && internacional
										? mainData.data.attributes.description
										: mainData.data.attributes.description}
								</ReactMarkdown>
								{/* {mainData.FlexKit && (
									<>
										<p>
											{mainData.FlexKit}{' '}
											<strong>{kitData.day}</strong> a las{' '}
											<strong>{kitData.time}</strong> en{' '}
											<strong>{kitData.place}</strong>.
										</p>
										<p className="mb-8">
											{mainData.addendum}
										</p>
										<a
											href={mainData.addendumBtnLink}
											className="no-underline bg-amRed py-3 px-4 text-white rounded-full font-bold"
										>
											{mainData.addendumBtn}
										</a>
									</>
								)} */}
							</div>
						</div>
					)}
					{/* {console.log("User isBoss: ", userData)} */}
					{!loading && userData.isBoss && (
						<div className="grid-col-3-6 p-8">
							<h2 className="text-xl font-bold tracking-tight text-amBlue sm:text-2xl">
								{language === "es" ? "Tu equipo" : "Your team"}
							</h2>
							{userData.minions.map((minion) => (
								<div
									key={`minion-data-${minion.name}`}
									className="my-3 w-full rounded-lg border border-gray-300 p-6">
									<p className="text-left text-lg font-bold">{minion.NOMBRE}</p>
									<p className="text-base text-left text-gray-500">
										{!!minion.ESQUEMACOLABORADOR && (
											<p className="text-left text-base text-gray-500">
												{language === "es" ? "Esquema" : "Scheme"}:{" "}
												<strong>{validateFlexStatus(minion.ESQUEMACOLABORADOR)}</strong>
											</p>
										)}
									</p>
								</div>
							))}
							{!internacional && (
								<div className="mt-2 flex w-full items-center justify-center">
									<a
										href={Matriz}
										target="_blank"
										rel="noopener noreferrer"
										className="rounded-full bg-amRed px-4 py-3 font-bold text-white no-underline">
										{language !== "es" && internacional
											? mainData.data.attributes.elegibilityBtn
											: mainData.data.attributes.elegibilityBtn}
									</a>
								</div>
							)}
						</div>
					)}
				</div>
				<div className="my-10 w-full rounded-lg bg-amYellow py-3">
					<div className="mx-auto flex items-center justify-center px-3 sm:px-6 lg:px-8">
						<span className="mr-3 flex rounded-lg bg-amBlack bg-opacity-10 p-1">
							<svg
								viewBox="0 0 24 24"
								xmlns="http://www.w3.org/2000/svg"
								className="h-6 w-6 text-amBlack fill-current">
								<path d="M0 0h24v24H0z" fill="none" />
								<path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm-1-7v2h2v-2h-2zm0-8v6h2V7h-2z" />
							</svg>
						</span>
						{!loading && (
							<p className="p-1 sm:px-6 lg:px-8 text-sm">
								{mainData.data.attributes.secondAlert
									? language !== "es" && internacional
										? mainData.data.attributes.secondAlert
										: mainData.data.attributes.secondAlert
									: `Por favor asegúrate de leer toda la información
								de preguntas frecuentes ya que incluye
								información importante sobre tu esquema de
								trabajo: cobertura de seguro de gastos médicos
								mayores fuera de México, boletos Flex Place,
								Flex Kit, etc.`}
							</p>
						)}
					</div>
				</div>
				<div className="flex flex-col items-end justify-center w-full mt-10">
					<h2 className="text-xl text-black font-bold mb-4 w-full text-left">
						{!loading && (
							<>
								{/* { mainData.data.attributes.requiredTitle } */}
								{language !== "es" && internacional
									? mainData.data.attributes.requiredTitle
									: mainData.data.attributes.requiredTitle}
							</>
						)}
					</h2>
					<div className="myownprose flex w-full list-inside list-disc flex-col">
						{!loading && (
							<ReactMarkdown>
								{/* { mainData.data.attributes.requiredText } */}
								{language !== "es" && internacional
									? mainData.data.attributes.requiredText
									: mainData.data.attributes.requiredText}
							</ReactMarkdown>
						)}
					</div>
				</div>
				<div className="mt-10 flex w-full flex-col items-end justify-center">
					<h2 className="mb-4 w-full text-left text-xl font-bold text-black">
						{!loading && (
							<>
								{/* { mainData.data.attributes.modalitiesTitle } */}
								{language !== "es" && internacional
									? mainData.data.attributes.modalitiesTitle
									: mainData.data.attributes.modalitiesTitle}
							</>
						)}
					</h2>
					<div className="mt-5 grid w-full grid-cols-3 gap-6">
						{!loading && mainData.data.attributes.placeImage ? (
							<>
								<div className="col-span-3 lg:col-span-1 flex items-center justify-center">
									{!loading && (
										<img
											src={flexPlace}
											className="h-auto w-8/12 lg:w-11/12 object-cover"
											alt="Flex-Place"
										/>
									)}
								</div>
								<div className="flex-prose col-span-3 space-y-3 rounded-lg bg-white p-4 text-sm lg:col-span-2">
									{!loading && <ReactMarkdown>{mainData.data.attributes.placeDescription}</ReactMarkdown>}
								</div>
							</>
						) : null}
						{!loading && mainData.data.attributes.leaveImage ? (
							<>
								<div className="col-span-3 flex flex-col items-center justify-center lg:col-span-1">
									{!loading && (
										<>
											<img
												src={flexLeave}
												alt="Flex-Leave"
												className="h-auto w-8/12 lg:w-11/12"
											/>
											<p className="text-gray-500 text-sm w-8/12 lg:w-11/12 mt-2">
												{mainData.data.attributes.leaveSubtitle}
											</p>
										</>
									)}
								</div>
								<div className="flex-prose col-span-3 space-y-3 rounded-lg bg-white p-4 text-sm lg:col-span-2">
									{!loading && <ReactMarkdown>{mainData.data.attributes.leaveDescription}</ReactMarkdown>}
								</div>
							</>
						) : null}
						{/* {console.log("MAin Data: ", mainData)} */}
						{!loading && mainData.data.attributes.daysImage ? (
							<>
								<div className="col-span-3 flex flex-col items-center justify-center lg:col-span-1">
									{!loading && (
										<>
										{/* {alert(`${urlValid.replace('/api/','')}${mainData.data.attributes.daysImage.data[0].attributes.url}`)} */}
											<img
												src={flexDays}
												alt="Diversity Flex-Days"
												className="h-auto w-8/12 lg:w-11/12"
											/>
											<p className="text-gray-500 text-sm w-8/12 lg:w-11/12 mt-2">
												{language !== "es" && internacional
													? mainData.data.attributes.daysSubtitle
													: mainData.data.attributes.daysSubtitle}
													{/* {mainData.data.attributes.daysSubtitle} */}
											</p>
										</>
									)}
								</div>
								<div className="flex-prose-none col-span-3 rounded-lg bg-white p-4 text-sm lg:col-span-2">
									{!loading && (
										<ReactMarkdown>
											{/* {mainData.data.attributes.daysDescription} */}
											{language !== "es" && internacional
												? mainData.data.attributes.daysDescription
												: mainData.data.attributes.daysDescription}
										</ReactMarkdown>
									)}
								</div>
							</>
						) : null}
						{!loading && mainData.data.attributes.timeImage ? (
							<>
								<div
									className={`col-span-3 ${
										mainData.data.attributes.timeDescription ? "lg:col-span-1" : "lg:col-span-3"
									} flex flex-col items-center justify-center`}>
									{!loading && (
										<>
											<img
												src={flexTime}
												alt="Flex-Time"
												className={`h-auto w-8/12 ${
													mainData.data.attributes.timeDescription ? "lg:w-11/12" : "lg:w-4/12 mx-auto"
												}`}
											/>
											<p
												className={`text-gray-500 text-sm w-8/12 ${
													mainData.data.attributes.timeDescription ? "lg:w-11/12" : "lg:w-4/12 mx-auto"
												} mt-2`}>
												{language !== "es" && internacional
													? mainData.data.attributes.timeSubtitle
													: mainData.data.attributes.timeSubtitle}
											</p>
										</>
									)}
								</div>
								{!loading && mainData.data.attributes.timeDescription ? (
									<div className="col-span-3 lg:col-span-2 bg-white rounded-lg overflow-hidden flex items-center justify-center p-4">
										<img
											src={flexTable}
											alt="Flex-Time Table"
											className="w-full h-full object-contain"
										/>
									</div>
								) : null}
							</>
						) : null}
					</div>
				</div>
				<p className="w-full text-xl font-bold text-left my-10" style={{ fontSize:'22px' }}>
					{language === "es" ? "Preguntas frecuentes" : "FAQs"}
				</p>
				<div className="w-full">
					{!loading && (
						<>
							{!internacional
								? mainData.data.attributes.faqs.map((faq, index) => (
										<FaqsNacional key={`nacional-preguntas-${index}`} faq={faq} index={index} />
									))
								: interFaqs.map((faq, index) => (
										<FaqsInternacional
											key={`internacional-preguntas-${index}`}
											faq={faq}
											language={language}
											index={index}
										/>
									))}
						</>
					)}
				</div>
			</section>
		);
	} else if (canAccess !== null && !canAccess) {
		return <Redirect to="/inicio" />;
	} else {
		return null;
	}
}

export default Flexibility;