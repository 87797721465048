import { travelBenefitsSpecialCases } from './validateTravelBenefitsSpecialCase';
import { isLocal, isFromDev, isFromQA } from './../core/Ambientes';
const lodash = require('lodash');	

const urlLocal = `${process.env.REACT_APP_LOCAL}`;
const urlDev = `${process.env.REACT_APP_CADMIN_DEV}`;
const urlQA = `${process.env.REACT_APP_CADMIN_QA}`
const urlProd = `${process.env.REACT_APP_CADMIN_PROD}`

const tokenLocal = `${process.env.REACT_APP_TOKEN_LOCAL}`;
const tokenDev   = `${process.env.REACT_APP_CADMIN_DEV_TOKEN}`;
const tokenQA 	 = `${process.env.REACT_APP_CADMIN_QA_TOKEN}`;
const tokenProd  = `${process.env.REACT_APP_API_TOKEN}`

// # Liga validada
const urlValid = isLocal === true ? urlLocal 
	: isFromDev === true ? urlDev
	: isFromQA  === true? urlQA
	: urlProd

// Se genera el token segun el ambiente
const tokenValid = isLocal === true ? tokenLocal
	: isFromDev === true ? tokenDev
	: isFromQA === true ? tokenQA
	: tokenProd

	const cabecera = new Headers({
		"Content-type": "application/json",
		Authorization: "Bearer " + tokenValid
	});

const URL = `${urlValid}`;
let notCalled = false;

export default function isTravelBenefitsActive() {
	if (
		testEmails() ||
		travelSpecificValidation() ||
		specialTravelBenefits(localStorage.getItem('email'))
	) {
		return true;
	} else {
		return false;
	}
}

export const validateFlexStatus = (data) => {
	switch (data) {
		case "N":
			return "En sitio";
		case "HOME OFFICE FULL-TIME":
			return "Remoto";
		case "HOME OFFICE PART-TIME":
			return "Parcial";
		default:
			return null;
	}
};
const validateFlexStatusBoolean = (data) => {
	switch (data) {
		case "N":
			return true;
		case "HOME OFFICE FULL-TIME":
			return true;
		case "HOME OFFICE PART-TIME":
			return true;
		default:
			return false;
	}
};


export const isFlexibilityActive = async () => {
	const isFlexActive = localStorage.getItem("esquemaColaborador");
	return validateFlexStatusBoolean(isFlexActive);
};

export function isBothActive() {
	if (testEmails()) {
		return true;
	} else {
		return false;
	}
}

function validateEmployee(data) {
	if (data === "NS") {
		return true;
	} else if (data === "I") {
		const company = localStorage.getItem("grupo");
		const nsd = "NSD";
		if (company.includes(nsd)) {
			return true;
		} else {
			return false;
		}
	} else {
		return false;
	}
}


function testEmails() {
	if (
		localStorage.getItem("email") === "jtapias@aeromexico.com" ||
		localStorage.getItem("email") === "jgarciab@aeromexico.com"
		// localStorage.getItem('email') === 'fsoler@aeromexico.com'
		// localStorage.getItem('email') === 'dcarbajal@aeromexico.com'
		// localStorage.getItem('email') === 'irios@aeromexico.com'
	) {
		return true;
	} else {
		return false;
	}
}

export const hasSpecialSale = async (fromWhere) => {
	return false;
};

export async function hasSpecialSaleOLD() {
	return false;
}

export function covidTickets() {
	return false;
}

function travelCompany(data) {
	const code = parseInt(data);
	switch (code) {
		case 2:
			return true;
		case 3:
			return true;
		case 99:
			return false;
		default:
			return false;
	}
}

function travelSpecificValidation() {
	if (
		travelCompany(localStorage.getItem("codigo_empresa_AM")) &&
		validateEmployee(localStorage.getItem("tipo_empleado_AM"))
	) {
		return true;
	} else {
		return false;
	}
}

function specialTravelBenefits(email) {
	const resultado = lodash.findIndex(travelBenefitsSpecialCases, function (o) {
		return o.email === email;
	});
	if (resultado >= 0) {
		return true;
	} else {
		return false;
	}
}