import React, { Component } from "react";
import "./css/gamreport.css";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

export default class GamReport extends Component {
	constructor() {
		super();
		this.state = {
			modalIsShow: false,
			loading: true,
			error: null,
			entries: null
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	componentDidMount() {
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
	}

	handleScroll = event => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 290) {
			this.setState({ hasScrolled: true });
		} else {
			this.setState({ hasScrolled: false });
		}
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				modalIsShow: false
			});
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}
	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				modalIsShow: false
			});
		}
	};
	setWrapperRef(node) {
		this.wrapperRef = node;
	}

	showAlertModal = () => {
		this.setState({
			modalIsShow: !this.state.modalIsShow
		});
	};

	render() {
		return (
			<div>
				<Link
					className={
						this.state.hasScrolled
							? "wrapperContGam wrapperContGamScroll"
							: "wrapperContGam"
					}
					to="/seguridad#gamreport"
				>
					<div className="opneModal">
						<div className="gamreportWrapper"></div>
						<div className="gamTextReport">
							<p>
								<Trans i18nKey="Footer.denuncia"></Trans>
							</p>
						</div>
					</div>
				</Link>
			</div>
		);
	}
}
