import isTravelBenefitsActive from '../isTravelBenefitsActive';

const DEV = false;
const level = parseInt(localStorage.getItem('nivel'));
const grupo = localStorage.getItem('grupo');
const email = localStorage.getItem('email');
const country = localStorage.getItem('pais');

export const validateMenu = () => {
	if (window.location.hostname === 'localhost') {
		return true;
	} else {
		if (country === 'MX' || country === 'US' || country === 'CA') {
			if (level > 0 || level !== null ) {
				return true;
			} else {
				return false;
			}
		} else {
			return false;
		}
	}
};

export const validateTravelBenefits = () => {
	if (isTravelBenefitsActive()) {
		return true;
	} else {
		return false;
	}
};