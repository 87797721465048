import React, { Component } from "react";
import * as contentful from "../../../lib/contentful";
import Modal from "react-awesome-modal";

import "./css/style.css";
import "./css/modal.css";

import close from "../../../assets/logo/close.svg";
import CookieConsent from "react-cookie-consent";

import ReactMarkdown from "react-markdown";
import getLocale from "../../../utils/getLocale";
import validateSindicate from "../../../utils/validateSindicate";

var noScroll = require("no-scroll");

export default class AlertHomeCookie extends Component {
	constructor() {
		super();
		this.state = {
			loading: true,
			error: null,
			entries: null,
			hasScrolledAlert: false,
			visible: true
		};
		this.escFunction = this.escFunction.bind(this);
		this.setWrapperRef = this.setWrapperRef.bind(this);
	}

	openModal() {
		this.setState({
			visible: true
		});
		noScroll.on();
	}

	closeModal() {
		this.setState({
			visible: false
		});
		noScroll.off();
	}

	componentDidMount() {
		this.fetchEntries();
		document.addEventListener("keydown", this.escFunction, false);
		window.addEventListener("scroll", this.handleScroll);
	}
	handleScroll = event => {
		const scrollTop = window.pageYOffset;
		if (scrollTop > 100) {
			this.setState({ hasScrolledAlert: true });
		} else {
			this.setState({ hasScrolledAlert: false });
		}
	};

	UNSAFE_componentWillMount() {
		document.addEventListener("mousedown", this.handleClick, false);
	}
	escFunction(event) {
		if (event.keyCode === 27) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	}

	componentWillUnmount() {
		document.removeEventListener("keydown", this.escFunction, false);
		document.removeEventListener("mousedown", this.handleClick, false);
	}

	handleClick = e => {
		if (this.wrapperRef && !this.wrapperRef.contains(e.target)) {
			this.setState({
				visible: false
			});
			noScroll.off();
		}
	};

	setWrapperRef(node) {
		this.wrapperRef = node;
	}
	fetchEntries = () => {
		contentful.client
			.getEntries({
				content_type: "homeNotification",
				include: 2,
				locale: getLocale()
			})
			.then(response => response.items)
			.then(data => {
				// AQUI DEBE DE IR LA VALIDACION PAARA EL SINDICALIZADO Y ALMACENAR LA RESPUESTA
				const array = this.getAlerts(
					data,
					localStorage.getItem("grupo")
				);
				
				this.setState({
					loading: false,
					entries: array
				});
			})
			.catch(error => {
				this.setState({
					loading: false,
					error: error
				});
			})
			.catch(err => console.error(err));
	};

	getAlerts = (data, sindico) => {
		let sindicato = validateSindicate(sindico);
		// let sindicato = false;
		let array = [];
		data.map(item => {
			if (item.fields.sindicato === sindicato) {
				array.push(item);
			}
		});
		return array;
	};

	render() {
		const { loading, entries } = this.state;
		return (
			<>
				{!loading &&
					entries &&
					entries.map(HomeAlert => (
						<div
							className={
								"nivelClaseAlert" +
								localStorage.getItem("nivel")
							}
						>
							<div
								className={
									HomeAlert.fields.notifyVisible +
									" " +
									"noDefaulttShowAlert" +
									" " +
									HomeAlert.fields.nivelesParaAlerta
										.toString()
										.replace(/,/g, " ")
								}
							>
								
								<CookieConsent
									location="none"
									acceptOnScroll={true}
									onAccept={() => {
										this.closeModal();
									}}
									acceptOnScrollPercentage={0}
									containerClasses="containerCoookie"
									buttonText="Continuar"
									cookieName={
										HomeAlert.fields.nombreCookieAlerta
									}
									// debug="true"
									buttonClasses="buttonAcceptCookie"
									buttonId="idAcceptCookieAlert"
									disableButtonStyles="true"
									// visible={this.state.visible}
									style={{
										background: "rgba(0,0,0,0)",
										position: "fixed",
										top: "0%",
										width: "100%",
										height: "0",
										overflow: "hidden",
										left: "0",
										position: "inherit",
										zIndex: "inherit"
									}}
									
									expires={HomeAlert.fields.expiracionDays} 
								>
									<Modal
										visible={this.state.visible}
										width="100%"
										height="auto"
										effect="fadeInDown"
										onClickAway={() => this.closeModal()}
									>
										<div
											ref={this.setWrapperRef}
											className="modal-notification homealertModalModification"
										>
											<div className="scrollModalAlert">
												<div class="imageNotification">
													{/* <p>{HomeAlert.fields.notifyType}</p> */}
													<img
														src={
															HomeAlert.fields
																.imageNotification
																.fields.file.url
														}
													/>
												</div>

												<div className="modalPadding">
													<div
														class="closeModal"
														href="javascript:void(0);"
														onClick={() =>
															this.closeModal()
														}
													>
														<img src={close} alt="cerar"></img>
													</div>
													<h1>
														{
															HomeAlert.fields
																.notifyType
														}
													</h1>
													<p>
														{
															HomeAlert.fields
																.notifyText
														}
													</p>
													<ReactMarkdown className="content">
														{
															HomeAlert.fields
																.contentNotification
														}
													</ReactMarkdown>
													{/* <div className="content" dangerouslySetInnerHTML={{ __html: HomeAlert.fields.contentNotification}}/> */}
												</div>
											</div>
										</div>
									</Modal>
								</CookieConsent>
							</div>
						</div>
					))}
			</>
		);
	}
}
